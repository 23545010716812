import queryString from "query-string"
import localStorage from "@/utils/localStorage"

export const trackAffiliate = location => {
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  if (urlParameters.query?.rid) {
    localStorage.set("referrer_id", urlParameters.query.rid)
  }
}
